import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";
import Document from "@/models/Document";
import DateTimeConverter from "@/services/converters/DateTimeConverter";
import {DateTime} from "luxon";

export enum BannerEnum {
    SPLASH = "SPLASH",
    REGULAR = "REGULAR",
    INTERSTITIAL = "INTERSTITIAL"
}

export enum BannerReference {
    HOME_TOP = "HOME_TOP",
    PRODUCTS = "PRODUCTS",
    NOVELTIES = "NOVELTIES",
    HOME_TOP_2 = "HOME_TOP_2"
}

@JsonConverter
export class TypeConverter implements JsonCustomConvert<BannerEnum> {
    deserialize(data: any): BannerEnum {
        let res = (<any>BannerEnum)[data]
        if (res == undefined) console.error("Error parsing enum", data)
        return res
    }
    serialize(data: BannerEnum): any {
        return data.toString()
    }
}

@JsonConverter
export class ReferenceConverter implements JsonCustomConvert<BannerReference> {
    deserialize(data: any): BannerReference {
        let res = (<any>BannerReference)[data]
        if (res == undefined) console.error("Error parsing enum", data)
        return res
    }
    serialize(data: BannerReference): any {
        return data.toString()
    }
}

@JsonObject("Banner")
export default class Banner {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("url", String, true)
    url?: string = undefined
    @JsonProperty("reference", ReferenceConverter, true)
    reference?: BannerReference = undefined
    @JsonProperty("type", TypeConverter, true)
    type?: BannerEnum = undefined
    @JsonProperty("image", Document, true)
    image?: Document = undefined
    @JsonProperty("active", Boolean, true)
    active?: boolean = undefined
    @JsonProperty("activatedAt", DateTimeConverter, true)
    activatedAt?: DateTime = undefined
}